






























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import VoucherAlert from '@/components/voucher/VoucherAlert.vue';
import VoucherTickets from '@/components/voucher/VoucherTickets.vue';
import VoucherPasses from '@/components/voucher/VoucherPasses.vue';
import VoucherClarification from '@/components/voucher/VoucherClarification.vue';
import WebRedemptionModule from '@/store/modules/.deprecated/moduleWebRedemption';
import RdmBarcodeScanner from '@/components/RdmBarcodeScanner.vue';
import _ from 'lodash';
import { nonProductionConsoleLog } from '@/utils/helpers';
import { profileModule } from '@/store/modules/moduleProfile';

@Component({
  components: {
    'rdm-voucher-tickets': VoucherTickets,
    'rdm-voucher-passes': VoucherPasses,
    'rdm-voucher-alert': VoucherAlert,
    'rdm-voucher-clarification': VoucherClarification,
    'rdm-barcode-scanner': RdmBarcodeScanner,
  },
})
export default class WebRedemption extends Vue {
  private pageTitle = this.$route?.meta?.title || '';
  private voucher = '';
  private webRedemption = getModule(WebRedemptionModule);
  private rules: Array<any> = [(v: string) => !!v || 'Voucher ID is required'];
  private activateScanner = false;
  private dialog = false;
  private ticketListDialog = false;
  private listModel = -1;
  private productList: Array<any> = [];

  private scanVoucher() {
    this.activateScanner = true;
    this.$gtag.event('redeem_cam_scan_activate', {
      org: this.Profile && this.Profile.org_code ? this.Profile?.org_code : 'UNKNOWN',
      account: this.Profile && this.Profile.email ? this.Profile?.email : 'UNKOWN',
    });
  }

  private redeemVoucher() {
    this.webRedemption.setMessage(null);
    const form = this.$refs.form as HTMLFormElement;
    if (form.validate()) {
      this.webRedemption
        .getTicketsAndPasses(this.voucher)
        .then(() => {
          this.ticketListDialog = true;
          // todo: check validate button in dialog to get correct selected ticket or pass and call validate
        })
        .catch((e: any) => {
          console.log('WebRedemption - redemption ticket error', e);
          if (
            e &&
            Array.isArray(e.message) &&
            e.message.length > 0 &&
            e.message[0] === 'Message.RedemptionTicketNotFound'
          ) {
            this.webRedemption.setMessage({
              type: 'error',
              title: 'Not Found',
              text: 'No ticket or voucher found matching this ID',
            });
          }
          // todo send to backend
        });
    }
  }

  private handleScanResult(result: any) {
    this.activateScanner = false;
    nonProductionConsoleLog('WebRedemption - handleScanResult result', result);
    if (result && result.length > 0) {
      try {
        const voucherSections = result[0].barcodeText.split(' ');
        if (voucherSections.length > 0) {
          let voucher = '';
          Object.values<string>(voucherSections).forEach((value) => {
            if (value.length > 0) {
              voucher += `${value} `;
            }
          });

          this.voucher = voucher.trim();
          this.$gtag.event('redeem_cam_scan', {
            org:
              this.Profile && this.Profile.org_code ? this.Profile?.org_code : 'UNKNOWN',
            account: this.Profile && this.Profile.email ? this.Profile?.email : 'UNKOWN',
            scan_code: this.voucher,
          });
        }
      } catch {
        this.voucher = 'UNKNOWN';
      }
    }
    if (window && window.navigator && _.isFunction(window.navigator.vibrate)) {
      window.navigator.vibrate([100, 50, 100]);
    }
  }

  private closeScanner() {
    this.activateScanner = false;
  }

  private closeMessage() {
    this.webRedemption.setMessage(null);
    this.webRedemption.reset();
  }

  private validateRaw() {
    this.webRedemption.setLoading(true);
    this.ticketListDialog = false;
    this.webRedemption
      .validate(true)
      .catch(() => {
        // todo send to backend
      })
      .finally(() => {
        this.webRedemption.setLoading(false);
        this.productList = this.webRedemption.products;
        const clarification = this.webRedemption.getClarificationType;
        nonProductionConsoleLog(
          'WebRedemption - validateRaw: product list',
          this.productList,
        );
        nonProductionConsoleLog(
          'WebRedemption - validateRaw: clarification',
          clarification,
        );

        this.$gtag.event('redeem_validate', {
          org: this.Profile && this.Profile.org_code ? this.Profile?.org_code : 'UNKNOWN',
          account: this.Profile && this.Profile.email ? this.Profile?.email : 'UNKOWN',
          no_products: Array.isArray(this.productList) ? this.productList.length : 'none',
          clarification: clarification,
        });

        if (
          clarification &&
          this.productList &&
          Array.isArray(this.productList) &&
          this.productList.length > 0
        ) {
          this.dialog = true;
        }
      });
  }

  private cancelValidation() {
    this.ticketListDialog = !this.ticketListDialog;
  }

  private cancelProduct() {
    this.listModel = -1;
    this.dialog = false;
  }

  private selectProduct() {
    const product = this.productList[this.listModel];
    this.webRedemption.setMessage(null);
    this.webRedemption.setProduct(product);
    this.webRedemption
      .validate(true)
      .catch(() => {
        // todo send to backend
      })
      .finally(() => {
        this.webRedemption.setLoading(false);
        this.$gtag.event('redeem_selected_product', {
          org: this.Profile && this.Profile.org_code ? this.Profile?.org_code : 'UNKNOWN',
          account: this.Profile && this.Profile.email ? this.Profile?.email : 'UNKOWN',
        });
      });
    // this.webRedemption
    //   .getTicketsAndPasses(product.id)
    //   .catch(err => {
    //     alertModule.addAlert(new Alert(`Failed to get tickets`));
    //     console.error(err);
    //   })
    //   .finally(() => {
    //     this.webRedemption.setLoading(false);
    //   });

    this.dialog = false;
  }

  private get message() {
    return this.webRedemption.getMessage;
  }

  private get loading() {
    return this.webRedemption.getLoading;
  }

  private get tickets() {
    return this.webRedemption.tickets;
  }

  private get passes() {
    return this.webRedemption.passes;
  }

  private get products() {
    return this.webRedemption.products;
  }

  get Profile() {
    return profileModule.Profile;
  }

  destroyed() {
    this.webRedemption.setMessage(null);
    this.webRedemption.reset();
  }
}
